export default {
    "task": {
        "auction": "Аукцион",
        "new": "Новая",
        "in_work": "В работе",
        "on_pause": "На паузе",
        "on_check": "На проверке",
        "on_rework": "На переделке",
        "completed": "Завершена",
        "to_work": "Начать выполнение",
        "to_pause": "Приостановить",
        "to_check": "На проверку",
        "to_rework": "Переделать",
        "to_completed": "Завершить",
        "to_new": "Новая",
        "resume": "Возобновить",
        "today": "Сегодня",
        "task": "Задача",
        "interest": "Интерес",
        "task2": "Задача",
        "history": "История",
        "created": "Создана",
        "dead_line": "Крайний срок",
        "priority": "Приоритет",
        "contractor": "Клиент",
        "potential_contractor": "Лид",
        "potential_contractor_client": "Клиент",
        "potential_contractor_region_name": "Бизнес регион",
        "potential_contractor_company_name": "Организация",
        "operator": "Ответственный",
        "visor": "Наблюдатель",
        "project": "Проект",
        "workgroup": "Рабочая группа",
        "add_subtask": "Добавить подзадачу",
        "share_to_chat": "Поделиться",
        "copy": "Скопировать",
        "edit": "Редактировать",
        "remove": "Удалить",
        "comments": "Комментарии",
        "main_task": "Основная задача:",
        "main_task_message": "Задача на основе сообщения в чате:",
        "main_task_comment": "Задача на основе комментария:",
        "main_task_files": "Задача на основе файла:",
        "main_task_order":"Задача на основе заказа:",
        "add": "Добавить",
        "no_description": "Описание отсутствует",
        "description": "Описание",
        "task_deleted": "Задача удалена",
        "error": "Ошибка",
        "task_not_found": "Такой задачи не существует либо она была удалена",
        "ultralow": "Очень низкий",
        "low": "Низкий",
        "middle": "Средний",
        "tall": "Высокий",
        "veryhigh": "Очень высокий",
        "1": "Низкий",
        "2": "Средний",
        "3": "Высокий",
        "owner": "Постановщик",
        "field_require_all": "Заполните обязательные поля",
        "field_require": "Обязательно для заполнения",
        "field_min_require": "Минимум {min} символов",
        "task_created": "Задача создана",
        "task_updated": "Задача обновлена",
        "edit_task": "Редактировать задачу",
        "add_task": "Add task",
        "task_based": "Задача на основе",
        "task_name": "Название задачи",
        "field_max_require": "Максимум {max} символов",
        "task_desc": "Описание задачи",
        "select_performer": "Выбрать исполнителя",
        "select_author": "Выбрать автора",
        "observers": "Наблюдатели",
        "select_observers": "Выбрать наблюдателей",
        "chat_message": "Сообщения в чате",
        "comment": "Комментария",
        "file2": "Файла",
        "order": "Заказ",
        "projects": "Проекты",
        "additionally": "Дополнительно",
        "additionally_fields": "Доп. поля",
        "make_it_subtask": "Сделать подзадачей",
        "make_it_subissue": "Участник обращения",
        "subtask": "Подзадачи",
        "change": "Изменить",
        "select": "Выбрать",
        "files": "Файлы",
        "save": "Сохранить",
        "create_and_open": "Сохранить и открыть",
        "create_and_create": "Сохранить и создать еще одну",
        "create_and_copy": "Сохранить и скопировать",
        "close": "Закрыть",
        "select_task": "Выбрать задачу",
        "no_time_limit": "Без срока",
        "user_name": "Имя пользователя",
        "select_user": "Выбрать пользователя",
        "project_name": "Название проекта",
        "select_project": "Выбрать проект",
        "working_group_name": "Название рабочей группы",
        "select_working_group": "Выбрать рабочую группу",
        "select_date": "Выбрать дату",
        "task-list-page": "Задачи",
        "task-calendar-page": "Календарь",
        "task-kanban-page": "Канбан",
        "task-gantt-page": "Диаграмма Ганта",
        "task-sprint-page": "Спринты",
        "name": "Название",
        "status": "Статус",
        "actions": "Действия",
        "no_data": "Нет данных",
        "load_more": "Загрузить еще",
        "create_fast_task": "Создать быструю задачу",
        "tasks_not_deadline": "Задачи без дедлайна",
        "week": "Неделя",
        "month": "Месяц",
        "quarter": "Квартал",
        "year": "Год",
        "day": "День",
        "action_disabled": "Действие запрещено",
        "large_priority": "Высокий приоритет",
        "very_large_priority": "Самый высокий приоритет",
        "small_priority": "Низкий приоритет",
        "very_low_priority": "Очень низкий приоритет",
        "accounting": "Учет",
        "participant_sprint": "Участник спринта",
        "user_not_owner": "Пользователь не является владельцем задачи.",
        "author": "Автор",
        "date_start": "Планируемая дата выезда",
        "date_start_fact": "Фактическая дата выезда",
        "date_start_plan": "Дата начала",
        "date_change": "Крайний срок|Дата начала изменены под родительскую задачу",
        "task_phone": "Телефон",
        "subject_matter": "Тематика",
        "add_appeal": "Добавить обращение",
        "edit_appeal": "Редактировать обращение",
        "appeal_name": "Название обращения",
        "open_task": "Открыть задачу",
        "email": "Электронная почта"
    }
}